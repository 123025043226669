import './app.scss';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'owl.carousel';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

jQuery(function ($) {
    $('#datetimepicker').datetimepicker({});

    const mobilenavToggle = () => {
        $('.navbar-toggle').on('click', function () {
            $(this).toggleClass('active');
            $('.main-menu').toggleClass('active');
            $('.header').toggleClass('active');
        });
    };
    mobilenavToggle();

    const menuToggle = () => {
        $('.menu-btn').on('click', function () {
            $(this).toggleClass('active');
            $('.header').toggleClass('opened');
            $('.main-menu').toggleClass('opened');
        });
    };
    menuToggle();

    const mobileMenu = () => {
        $('.menu-item-has-children > a').on('click', function (e) {
            e.preventDefault();
            const currentDropdown = $(this).next('.sub-menu');

            currentDropdown.slideToggle();
            $('.sub-menu').not(currentDropdown).slideUp();

            return false;
        });
    };
    mobileMenu();

    const showBooking = () => {
        $('.booking').on('click', function (e) {
            e.preventDefault();
            $('.popup-form').addClass('active');
        });
    };
    showBooking();

    const hideBooking = () => {
        $('.form-close').on('click', function (e) {
            e.preventDefault();
            $('.popup-form').removeClass('active');
        });
    };
    hideBooking();

    const hidePopup = () => {
        $('.close').on('click', function (e) {
            e.preventDefault();
            $('.popup').addClass('hide');
            $('.popup').removeClass('show');
        });
    };
    hidePopup();

    const showBookingBox = () => {
        setTimeout(function () {
            $('.popup').addClass('show');
        }, 3000);
    };
    showBookingBox();

    let scrollSections = (sTtarget, targetElement) => {
        gsap.to(sTtarget, {
            scrollTrigger: {
                trigger: targetElement,
                toggleClass: 'scrolled',
            },
            duration: 1,
        });
    };

    scrollSections('body', '.header');

    if ($('body').hasClass('home')) {
        ScrollTrigger.defaults({
            scroller: '.section-wrapper',
        });
        
        scrollSections('body', '.header');
        scrollSections('.section-1', '.section-1');
        scrollSections('.section-2', '.section-2');
        scrollSections('.section-3', '.section-3');
        scrollSections('.section-4', '.section-4');
        scrollSections('.section-5', '.section-5');
        scrollSections('.section-6', '.section-6');
    }
});
